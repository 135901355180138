<template>
  <v-app>
      <m-mesages></m-mesages>
    <router-view v-if="router_load" />
    
</v-app>
  
</template>

<script>
import MMesages from './components/MMesages.vue'
import {useRoute} from 'vue-router'


const c_route = useRoute()
export default {
  name: 'HelloWorld',
  components:{
    MMesages
  },
  data:()=>({
    db:'',
    OS:'',
    status_bar: true,
    server_addres: 'http://localhost:8080/',
    router_load: false
  }
  ),
  
  computed:{
    server_connect(){
      return this.$store.getters.SERVER_CONNECT
    },
    currentPath(){
      return c_route
    }
  },
  methods:{
    help_message_current_status(name){return this.$store.getters.HELP_MESSAGE_STATUS(name)},
    check_permission(id){
          return this.$store.getters.USER_CHECK_PERMISSION(id)
      },
    currentDate(){
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, '0');
      var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      var hh = String(today.getHours()).padStart(2, '0');
      var MM = String(today.getMinutes()).padStart(2, '0');
      var ss = String(today.getSeconds()).padStart(2, '0');

      var yyyy = today.getFullYear();
      // today = dd+'.'+mm+'.'+yyyy + ' ' + hh + ':'+MM+':'+ss;
      today = yyyy +'-'+mm+'-'+dd;

      return today
      },
      currentDateTime(){
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, '0');
      var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      var hh = String(today.getHours()).padStart(2, '0');
      var MM = String(today.getMinutes()).padStart(2, '0');
      var ss = String(today.getSeconds()).padStart(2, '0');

      var yyyy = today.getFullYear();
      // today = dd+'.'+mm+'.'+yyyy + ' ' + hh + ':'+MM+':'+ss;
      today = yyyy +'-'+mm+'-'+dd + 'T' + hh+':'+MM+':'+ss;

      return today
      },
      async getSettings(){
      await fetch("settings.json")
        .then(response => response.json())
        .then(data => (this.settings = data,
          this.have_settings = true
        ));
        await this.$store.dispatch('SET_SERVER', this.settings['server'])
        await this.$store.dispatch('SET_REFRESH_ACCESS_TOKEN_URL', this.settings['RAT_URL'])
        this.server_addres = this.settings['web_server']
    }
  },
  watch:{
  
  },

  async created(){
    console.log('start init')
    
    
    this.OS = Capacitor.getPlatform()
    // console.log(currentPath)
    await this.$router.replace('/')
    await this.getSettings()

    await this.$router.isReady().then( async response=>{
      this.router_load = true
    })
     
    

      
     
    
  },
  
}

</script>

<style>
:root{
  --primary-color: #018FA8;
}

@font-face {
	font-family: 'SamsungSharpSans-Bold';
	src: url('@/assets/fonts/samsungsharpsans-bold.eot'); /* IE 9 Compatibility Mode */
	src: url('@/assets/fonts/samsungsharpsans-bold.eot?#iefix') format('embedded-opentype'), /* IE < 9 */
		url('@/assets/fonts/samsungsharpsans-bold.woff2') format('woff2'), /* Super Modern Browsers */
		url('@/assets/fonts/samsungsharpsans-bold.woff') format('woff'), /* Firefox >= 3.6, any other modern browser */
		url('@/assets/fonts/samsungsharpsans-bold.ttf') format('truetype'), /* Safari, Android, iOS */
		url('@/assets/fonts/samsungsharpsans-bold.svg#samsungsharpsans-bold') format('svg'); /* Chrome < 4, Legacy iOS */
}

@font-face {
	font-family: 'Pragmaticaregular.Kz';
	src: 
		url('@/assets/fonts/Pragmaticaregular.Kz.ttf') format('truetype'), /* Safari, Android, iOS */
}

@font-face {
	font-family: 'KZ Century Gothic';
	src: 
		url('@/assets/fonts/centurygothic.ttf') format('truetype'), /* Safari, Android, iOS */
}

@font-face {
	font-family: 'KZ Century Gothic Bold';
	src: 
		url('@/assets/fonts/centurygothic_bold.ttf') format('truetype'), /* Safari, Android, iOS */
}

.inter {
  font-family: "Inter", serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}


::-webkit-scrollbar {
    width: 8px;
}
 
::-webkit-scrollbar-track {
    border-radius: 8px;
    background-color: #eee;
}
 
::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: var(--primary-color);
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
